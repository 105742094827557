import React, { useEffect, useRef, useState } from "react";
import useStream from "@/hooks/use-stream";
import "../../pages/event-page/preshow/pre-show.scss";
import { AgoraWebClient } from "@/utils/agora-web-client";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";
import { AgoraMediaStream } from "@/utils/types";
import { CurrentTime, formatDate } from "@/utils/helper";
import DashBoardStream from "./dashboard-stream/dashBoardStream";
import "./DashBoardEvents.scss";
import WelcomeLoader from "@/pages/WelcomeLoader/WelcomeLoader";
import Timer from "../timer/timer";
import { formatDay, formatTime } from "@/utils/helper";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import { ApiUrls } from "@/utils/api/constant/api.constant";

const DashBoardEvents = ({ VisitDetails, eventRoomStoreLocal }: any) => {
  const { initializeSocket, emitEvent } = useSocket();
  const { allStreams, remoteStreams } = useStream(eventRoomStoreLocal);
  const defaultStreams: any = { mainStreams: [], injectedStreams: [] };
  const [liveStreams, setLiveStreams]: any = useState(defaultStreams);
  const me = eventRoomStoreLocal.state.me;
  const isLoader = useRef(true);
  console.log(eventRoomStoreLocal, "eventRoomStoreLocal");
  useEffect(() => {
    const sideStreamsArray = allStreams.filter((stream) => {
      if (stream.streamID != me.info.guide.id) {
        return true;
      }
      return false;
    });
    setLiveStreams({
      mainStreams: allStreams.filter((stream) => {
        if (stream.streamID != me.info.uid) {
          return true;
        }
        return false;
      }),
      injectStreams: sideStreamsArray,
    });
    setTimeout(() => {
      isLoader.current = false;
    }, 0);
  }, [remoteStreams, eventRoomStoreLocal.state.rtc.remoteStreams, allStreams]);

  useEffect(() => {
    initializeSocket();
    if (eventRoomStoreLocal.state.me.info.role == "admin") {
      emitEvent(ESocketEvents.JoinVisit, {
        visit_id: eventRoomStoreLocal.state.me.channelName,
        user_role: eventRoomStoreLocal.state.me.info.role,
      });
    }
  }, [eventRoomStoreLocal.state.me.info.role]);

  const [showdetails, setshowdetails] = useState(false);
  const detailsHandler = () => {
    setshowdetails(!showdetails);
  };

  return (
    <>
      {showdetails && (
        <div className="host-dashboard-page-popup">
          <div className="host-dashboard-page-popup-close">
            <div
              className="close host-dashboard-page-popup-close-button"
              onClick={detailsHandler}
            >
              <CloseIcon fontSize="small" />
            </div>
          </div>
          <div className="host-dashboard-page-popup-content">
            <div className="host-dashboard-page-popup-content-name">
              {VisitDetails.visit_name} - &nbsp;
              {formatDay(
                eventRoomStoreLocal.convertTimeFromUTC(VisitDetails.time_in_utc)
              )}
              &nbsp;
              {formatTime(
                eventRoomStoreLocal.convertTimeFromUTC(VisitDetails.time_in_utc)
              )}
              &nbsp;
            </div>
            <div className="host-dashboard-page-popup-content-details">
              <div className="host-dashboard-page-popup-content-details-box-content">
                <div className="host-dashboard-page-popup-content-details-box-content-list">
                  <span className="host-dashboard-page-popup-content-details-box-content-list-name">
                    Guide Name -
                  </span>
                  <div className="host-dashboard-page-popup-content-details-box-content-list-header">
                    {VisitDetails.guide_name}
                    <a href={`tel:${VisitDetails.guide_phone}`}>
                      {VisitDetails.guide_phone
                        ? `(${VisitDetails.guide_phone})`
                        : ""}
                    </a>
                  </div>
                </div>
                <div className="host-dashboard-page-popup-content-details-box-content-list">
                  <span className="host-dashboard-page-popup-content-details-box-content-list-name">
                    Host Name -
                  </span>
                  <div className="host-dashboard-page-popup-content-details-box-content-list-header">
                    {VisitDetails.host_username}
                    <a href={`tel:${VisitDetails.host_phone}`}>
                      {VisitDetails.host_phone
                        ? `(${VisitDetails.host_phone})`
                        : ""}
                    </a>
                  </div>
                </div>
                <div className="host-dashboard-page-popup-content-details-box-content-list">
                  <span className="host-dashboard-page-popup-content-details-box-content-list-name">
                    Residence Name -
                  </span>
                  <div className="host-dashboard-page-popup-content-details-box-content-list-header">
                    {VisitDetails.residence_name}
                    <a href={`tel:${VisitDetails.residence_phone}`}>
                      {VisitDetails.residence_phone
                        ? `(${VisitDetails.residence_phone})`
                        : ""}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="events-section">
        <div className="events-tiles">
          <Timer timerType={"event-error"} VisitDetails={VisitDetails} />
          <div className="events-images">
            {isLoader.current ? (
              <WelcomeLoader />
            ) : (
              <>
                {eventRoomStoreLocal.state.rtc.remoteStreams.size > 0 ? (
                  <DashBoardStream
                    liveStreams={liveStreams}
                    eventRoomStoreLocal={eventRoomStoreLocal}
                    VisitDetails={VisitDetails}
                  />
                ) : (
                  <>
                    <h3 className="dashboard-events-content-error">
                      No one has Joined
                    </h3>
                  </>
                )}
              </>
            )}
          </div>
          <div
            className="event-redirection"
            onClick={() => {
              let userChannel = VisitDetails?.admin_link.replace(
                `${ApiUrls.channelUrl}`,
                ""
              );
              window.open(
                ApiUrls.frontendUrl + "meeting/" + userChannel + "?show=1",
                "_blank"
              );
            }}
          >
            <LaunchIcon fontSize="small" />
          </div>
          <span className="event-time-details">
            <span>{VisitDetails?.visit_name}</span> - &nbsp;
            {formatDate(
              eventRoomStoreLocal.convertTimeFromUTC(VisitDetails?.time_in_utc)
            )}
            &nbsp;
            {CurrentTime(
              eventRoomStoreLocal.convertTimeFromUTC(VisitDetails?.time_in_utc)
            )}
            &nbsp;
            <div className="host-dashboard-page-info-button">
              <InfoOutlinedIcon onClick={detailsHandler} />
            </div>
          </span>
        </div>
      </div>
    </>
  );
};
export default DashBoardEvents;
