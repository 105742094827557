import React, { useEffect, useState, useRef } from "react";
import ShowTalkStreamsMultiMasterClass from "@/components/show-talk/show-talk-many-streams-masterclass/show-talk-many-streams-masterclass";
import useStream from "@/hooks/use-stream";
import "./pre-show.scss";
import ChatMenu from "@/components/chat-menu/chat-menu";
import PopUp from "@/components/popup/popup";
import Timer from "@/components/timer/timer";
import { eventRoomStore } from "@/stores/event";
import { useEventRoomState } from "@/containers/root-container";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HostOptions from "@/components/show-talk/show-talk-bottom-options/host-options/host-options";
import Loading from "@/components/loading/loading";
import NetworkIndicator from "@/components/network-indicator/network-indicator";
import { AgoraWebClient } from "@/utils/agora-web-client";
import ShowTalkBottomOptions from "@/components/show-talk/show-talk-bottom-options/show-talk-bottom-options";
import hostOptions from "@/components/show-talk/show-talk-bottom-options/host-options/host-options";
import classNames from "classnames";
import Logo from "@/assets/logo.png";
import PreCallScreen from "@/pages/precall/PrecallScreen";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";
import ConfirmationPopUp from "@/components/popup/ConfirmationPopup";
import FullScreenConfirmation from "@/components/popup/fullScreenPopup";
import ShowParticipants from "@/components/show-talk/show-participants-block/ShowParticipants";
import Endscreen from "@/pages/EndScreen/Endscreen";
import PreCallScreenInvitee from "@/pages/precall/PreCallInvitee";
import InviteeOptions from "@/components/show-talk/show-talk-bottom-options/invitee-options/invitee-options";

const PreTalkShow = () => {
  // const [visitDetailsData, setVisitDetailsData] = useState<any>();
  const [isCallEnd, setIsCallEnd] = useState(false);
  const [isCallEndMlt, setIsCallEndMtl] = useState(false);
  const { allStreams, adminStream, guideStream } = useStream(eventRoomStore);
  const eventRoomState = useEventRoomState();
  const webClient = eventRoomStore.rtcClient as AgoraWebClient;
  const defaultStreams: any = { mainStreams: [], injectedStreams: [] };
  const [liveStreams, setLiveStreams] = useState(defaultStreams);
  const { initializeSocket, addSocketEvent, removeSocketEvent, emitEvent } =
    useSocket();
  const [participants, setParticipants] = useState(false);
  const [showJoinRequests, setShowJoinRequests] = useState(true);
  const handelParticipantsBtn = () => {
    setParticipants(!participants);
  };

  // const getVisitDetails = async () => {
  //   try {
  //     const url = window.location.href;
  //     const urlObj = new URL(url);
  //     const pathSegments = urlObj.pathname.split('/');
  //     const meetingId = pathSegments[pathSegments.length - 1];
      
  //     const visitDetails = await eventRoomStore.fetchVisitDetails(meetingId);
  //     setVisitDetailsData(visitDetails);
  //   } catch (error) {
  //     console.error('Error fetching visit details:', error);
  //   }
  // };

  // useEffect(() => {
  //   getVisitDetails();
  //   if(visitDetailsData?.status === 200) {
  //     if(eventRoomState.me.info.role === "residence" && visitDetailsData?.visit?.guide_closed === true && visitDetailsData?.visit?.visit_closed === false) {
  //       setIsCallEnd(true)
  //     }
  //     if(eventRoomState.me.info.role === "multiconnection" && visitDetailsData?.visit?.guide_closed === true && visitDetailsData?.visit?.visit_closed === false) {
  //       setIsCallEndMtl(true)
  //     }
  //     if(eventRoomState.me.info.role === "multiconnection" || eventRoomState.me.info.role === "residence" && visitDetailsData?.visit?.visit_closed === true) {
  //       window.location.href = "/end";
  //     }
  //   }
  // }, [visitDetailsData])

  // console.log(visitDetailsData, "visitDetailsData")

  useEffect(() => {
    let mainLiveStream;
    let mainLiveStreamID: any;
    if (!eventRoomState.gridView) {
      const pinid = Number(eventRoomState.pinId);
      let streamIndex = allStreams.findIndex((x) => x.streamID == pinid);
      if (pinid !== 0 && streamIndex > -1) {
        mainLiveStream = allStreams[streamIndex];
        mainLiveStreamID = allStreams[streamIndex].streamID;
      } else if (eventRoomStore.state.me.info.role === "guide") {
        const localStream = eventRoomState.rtc.localStream;
        mainLiveStreamID = localStream?.streamID;
        mainLiveStream = localStream;
      } else {
        mainLiveStreamID =
          eventRoomStore.state.me.mainLiveStreamID ||
          (allStreams.length && guideStream && guideStream.streamID) ||
          0;
        mainLiveStream = allStreams.find(
          (stream) => stream.streamID === mainLiveStreamID
        );
      }
      if (
        mainLiveStream &&
        eventRoomState.rtc.joined &&
        eventRoomState.isLive
      ) {
        webClient.setRemoteVideoStreamType(mainLiveStream.stream.streamID, 0);
        // update main stream id at root leven event store to be used other places
        eventRoomStore.setMeAttr("mainLiveStreamID", mainLiveStreamID);
      }
      const sideStreamsArray = allStreams.filter((stream) => {
        if (stream.streamID != mainLiveStreamID) {
          return true;
        }
        return false;
      });
      setLiveStreams({
        mainStreams: (mainLiveStream && [mainLiveStream]) || [],
        injectedStreams: sideStreamsArray,
      });
    } else {
      setLiveStreams({
        mainStreams: [],
        injectedStreams: allStreams,
      });
      eventRoomStore.setMeAttr("mainLiveStreamID", 0);
    }
  }, [allStreams, eventRoomState.gridView, eventRoomState.pinId]);

  const [showChat, setShowChats] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [IsConfirmationPopUp, setIsConfirmationPopup] = useState(false);
  const [IsFullScreenConfirmation, setIsFullScreenConfirmation] =
    useState(false);
  const [showSideStream, setShowSideStream] = useState(false);
  const [GuideText, setGuideText] = useState({
    host: "Guide is coming soon",
    residence: "Votre guide personnel arrive prochainement",
  });
  const handleUnload = () => {
    emitEvent(ESocketEvents.REQUEST_CANCEL, {
      visit_id: eventRoomState.me.channelName,
      user_id: eventRoomState.me.uid,
      user_name: eventRoomState.me.info.name,
      user_role: eventRoomState.me.info.role,
    });
  };

  useEffect(() => {

    window.addEventListener("beforeunload", handleUnload);
    window.addEventListener("popstate", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      window.removeEventListener("popstate", handleUnload);
    };
  }, [eventRoomState.me.channelName, eventRoomState.me.uid, eventRoomState.me.info.name, eventRoomState.me.info.role]);

  useEffect(() => {
    return () => {
      handleUnload()
    }
  }, [])

  const handleShowPopUp = () => {
    setShowPopUp((prevState) => !prevState);
  };

  const toggleConfirmationPopup = () => setIsConfirmationPopup((open) => !open);
  const toggleFullScreenConfirmation = () => {
    setIsFullScreenConfirmation((open) => !open);
  };
  const handleChatClick = (val = showChat) => {
    //setShowChats((prevState) => !prevState);
    setShowChats(!val);
  };

  const isRequest = useRef(false);
  const [requestResData, setRequestResData] = useState<
    { visit_id: any; user_id: any; user_name: any }[]
  >([]);
  const [residenceRequest, setresidenceRequest] = useState({
    visit_id: "",
    user_id: "",
    user_name: "",
    user_role: "",
  });

  const residenceRequestHandler = (user_id: any) => {
    let filtered = requestResData.filter((a) => a.user_id != user_id);
    setRequestResData(filtered);
  };

  useEffect(() => {
    initializeSocket();
    addSocketEvent(
      ESocketEvents.ACCESS_WAIT,
      ({ visit_id, user_id, user_name, user_role }) => {
        isRequest.current = true;
        setresidenceRequest({
          ...residenceRequest,
          visit_id: visit_id,
          user_id: user_id,
          user_name: user_name,
          user_role: user_role,
        });
        setRequestResData((prevData) => [
          ...prevData,
          {
            visit_id: visit_id,
            user_id: user_id,
            user_name: user_name,
            user_role: user_role,
          },
        ]);
      }
    );

    addSocketEvent(ESocketEvents.IS_NOISE_CANCELLATION_ON, (data: any) => {
      eventRoomStore.updateNoiseCancellation(data.is_noise_cancellation_on);
    });

    addSocketEvent(ESocketEvents.ACCESS_ACCEPT, (data) => {
      console.log(
        "precallsocket inside socket visit_id",
        data,
        eventRoomStore._state.me.info.role
      );
      if (eventRoomStore._state.me.uid == data.user_id) {
        if (data.accepted) {
          eventRoomStore.hasVisitStarted(true);
          // sessionStorage.setItem("visitStarted", "true");
        } else {
          window.location.href = "/end";
        }
      }
    });
    addSocketEvent(
      ESocketEvents.REQUEST_CANCEL,
      ({ visit_id, user_id, user_name }) => {
        isRequest.current = false;
        setresidenceRequest({
          ...residenceRequest,
          visit_id: "",
          user_id: "",
          user_name: "",
          user_role: "",
        });
        residenceRequestHandler(user_id);
      }
    );
    addSocketEvent(ESocketEvents.EndVisit, (visit_id) => {
      if (visit_id == eventRoomStore._state.me.channelName) {
        if (eventRoomStore._state.me.info.role === "guide") {
          window.location.href = "/end-visit";
        } else {
          window.location.href = "/end";
        }
      }
    });

    addSocketEvent(ESocketEvents.GUIDE_LEAVE, (visit_id) => {
      if (visit_id == eventRoomStore._state.me.channelName) {
        if (
          eventRoomStore._state.me.info.role === "residence" ||
          eventRoomStore._state.me.info.role === "host" ||
          eventRoomStore._state.me.info.role === "multiconnection"
        ) {
          {
            eventRoomStore._state.me.info.role === "residence" &&
              setIsCallEnd(true);
          }
          {
            eventRoomStore._state.me.info.role === "multiconnection" &&
              setIsCallEndMtl(true);
          }
          // setTimeout(() => {
          //   console.log(setTimeout, 'end-call-time')
          //   window.location.href = '/end';
          // }, 60000)
        }
        if (eventRoomStore._state.me.info.role === "invitee") {
          window.location.href = "/end";
        }
      }
      setGuideText({
        host: "Guide has Left the Visit",
        residence:
          "Votre visite Culturelle est terminée. Cliquez ici pour quitter",
      });
    });

    return () => {
      removeSocketEvent(ESocketEvents.EndVisit);
      removeSocketEvent(ESocketEvents.GUIDE_LEAVE);
    };
  }, []);

  useEffect(() => {
    if(eventRoomState.me.info.role != "admin"){
      addSocketEvent(ESocketEvents.ADMIN_TILE, ({visit_id, value}) => {
        if (visit_id == eventRoomStore._state.me.channelName) {
          eventRoomStore.updateAdminVideoPlayerStatus(value);
        }
      });
    }
  }, [])

  // useEffect(() => {
  //   window.addEventListener("beforeunload", RequstHandlerClick);
  //   window.addEventListener("popstate", RequstHandlerClick);
  // }, []);

  // useEffect(() => {
  //   if (eventRoomState.hasStarted) {
  //     window.removeEventListener("beforeunload", RequstHandlerClick);
  //     window.removeEventListener("popstate", RequstHandlerClick);
  //   }
  // }, [sessionStorage.getItem("visitStarted")]);

  useEffect(() => {
    if (eventRoomState.hasStarted) {
      if (
        eventRoomState.me.info.role == "guide" ||
        eventRoomState.me.info.role == "host"
      ) {
        emitEvent(ESocketEvents.JoinVisit, {
          visit_id: eventRoomState.me.channelName,
          user_role: eventRoomState.me.info.role,
        });
        emitEvent(ESocketEvents.StartVisit, {
          visit_id: eventRoomState.me.channelName,
        });
      }

      if (
        eventRoomState.me.info.role == "host" &&
        sessionStorage.getItem("recording") == "true"
      ) {
        emitEvent(ESocketEvents.Record, {
          visit_id: eventRoomState.me.channelName,
          guide_id: eventRoomState.me.info.guide.id,
        });
      }
    }
    if (
      eventRoomState.me.info.role == "residence" ||
      eventRoomState.me.info.role == "invitee" ||
      eventRoomState.me.info.role == "admin" ||
      eventRoomState.me.info.role == "multiconnection"
    ) {
      console.log("Join Visit emit");
      emitEvent(ESocketEvents.JoinVisit, {
        visit_id: eventRoomState.me.channelName,
        user_role: eventRoomState.me.info.role,
      });
    }
  }, [eventRoomState.hasStarted, eventRoomState.me.info.role]);

  useEffect(() => {
    if(eventRoomState.hasStarted && eventRoomState.hasJoinedVisit) {
      console.log(eventRoomState.hasJoinedVisit, "Join Visit emitted");
      emitEvent(ESocketEvents.JoinVisit, {
        visit_id: eventRoomState.me.channelName,
        user_role: eventRoomState.me.info.role,
      });
    }
  }, [eventRoomState.hasJoinedVisit])

  useEffect(() => {
    const matchingStream = allStreams.find(
      (stream: any) => stream.streamID === residenceRequest.user_id
    );
    const matchingRequest = requestResData.find(
      (request: any) => request.user_id === residenceRequest.user_id
    );
    if (matchingStream) {
      isRequest.current = false; // Set isRequest to false if a matching stream is found
      residenceRequestHandler(residenceRequest.user_id);
    }
    if (matchingRequest) {
      isRequest.current = false; // Set isRequest to false if a matching stream is found
      setresidenceRequest({
        ...residenceRequest,
        visit_id: "",
        user_id: "",
        user_name: "",
        user_role: "",
      });
    }
  }, [allStreams, residenceRequest]);
  const AcceptHandler = (
    val: any,
    visit_id: any,
    user_id: any,
    user_name: any,
    user_role: any
  ) => {
    emitEvent(ESocketEvents.ACCESS_ACCEPT, {
      visit_id: visit_id,
      user_id: user_id,
      user_name: user_name,
      user_role: user_role,
      accepted: val,
    });
    isRequest.current = false;
    residenceRequestHandler(user_id);
    // let filtered = requestResData.filter((a) => a.user_id != user_id);
    // setRequestResData(filtered);
    // Filter out the accepted request from requestResData
    // const updatedRequestResData = requestResData.filter(
    //   (item) =>
    //     item.visit_id !== residenceRequest.visit_id &&
    //     item.user_id !== residenceRequest.user_id &&
    //     item.user_name !== residenceRequest.user_name
    // );
    // setRequestResData(updatedRequestResData);
    return () => {
      removeSocketEvent(ESocketEvents.ACCESS_ACCEPT);
    };
  };

  const handleSwtichStream = (streamID: any) => {
    console.log("stream which pin", streamID);
    const activeMainLiveStream = liveStreams.mainStreams[0];
    // sessionStorage.setItem("pinid", streamID);
    eventRoomStore.setMeAttr("mainLiveStreamID", streamID);
    activeMainLiveStream &&
      webClient.setRemoteVideoStreamType(activeMainLiveStream.streamID, 1);
    webClient.setRemoteVideoStreamType(streamID, 0);
    eventRoomStore.setPinId(streamID);
  };

  return (
    <>
      <section
        className={classNames({
          _inner: true,
          "preshow-talk": true,
          "masterclass-container": true,
        })}
        id="preshow-talks"
      >
        <>
          {eventRoomState.hasStarted ? (
            <section
              className={`streams-container _left ${
                allStreams.length === 0 ? "flex-center" : ""
              }
                 ${"masterclass"}`}
              id="main-streams"
            >
              <Loading />
              <div
                className={`top-bar ${showSideStream && "top-bar-absolute"} `}
              >
                <div className="top-bar-logo">
                  <img src={Logo} />
                </div>
                <div className="top-bar-time">
                  {(eventRoomStore._state.me.info.role == "guide" ||
                    eventRoomStore._state.me.info.role == "host") && <Timer />}
                  <NetworkIndicator />
                </div>
              </div>
              <div
                className={`stream-section`}
                style={{
                  display: "flex",
                }}
              >
                {/* {isRequest && requestResData.map((request) => (
                  <div className="stream-join-request" key={request.user_id}>
                    <span>{request.user_name}</span>
                    <div className="stream-join-request-button">
                      <button
                        onClick={() => {
                          AcceptHandler(true);
                        }}
                        className="accept-button"
                      >
                        Accept
                      </button>
                      {eventRoomState.me.info.role === "host" && (
                        <button
                          onClick={() => {
                            AcceptHandler(false);
                          }}
                          className="reject-button"
                        >
                          Reject
                        </button>
                      )}
                    </div>
                  </div>
                ))} */}
                {((eventRoomState.me.info.role === "guide" &&
                  residenceRequest.user_role !== "invitee") ||
                  eventRoomState.me.info.role === "host") && (
                  <>
                    {showJoinRequests && isRequest.current && requestResData.length > 1 ? (
                      <div className="stream-join-requests">
                        <div className="stream-join-request">
                          <span>
                            {requestResData.length} users wants to join this
                            call
                          </span>
                          <a
                            href="#"
                            onClick={() => {
                              setShowJoinRequests(false)
                              setParticipants(true);
                              isRequest.current = false;
                            }}
                          >
                            View all
                          </a>
                        </div>
                      </div>
                    ) : (
                      isRequest.current && (
                        <div
                          className="stream-join-request"
                          key={residenceRequest.user_id}
                        >
                          <span>{residenceRequest.user_name}</span>
                          <div className="stream-join-request-button">
                            {eventRoomState.me.info.role === "host" && (
                              <button
                                onClick={() => {
                                  AcceptHandler(
                                    false,
                                    residenceRequest.visit_id,
                                    residenceRequest.user_id,
                                    residenceRequest.user_name,
                                    residenceRequest.user_role
                                  );
                                }}
                                className="reject-button"
                              >
                                Reject
                              </button>
                            )}
                            <button
                              onClick={() => {
                                AcceptHandler(
                                  true,
                                  residenceRequest.visit_id,
                                  residenceRequest.user_id,
                                  residenceRequest.user_name,
                                  residenceRequest.user_role
                                );
                              }}
                              className="accept-button"
                            >
                              Accept
                            </button>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
                <ShowTalkStreamsMultiMasterClass
                  GuideText={GuideText}
                  liveStreams={liveStreams}
                  participants={participants}
                  handleSwtichStream={handleSwtichStream}
                  handleChatClick={handleChatClick}
                  handleShowPopUp={handleShowPopUp}
                  setShowSideStream={setShowSideStream}
                  toggleFullScreenConfirmation={toggleFullScreenConfirmation}
                  handelParticipantsBtn={handelParticipantsBtn}
                  AcceptHandler={AcceptHandler}
                />
                {participants && (
                  <ShowParticipants
                    liveStreams={liveStreams}
                    handleSwtichStream={handleSwtichStream}
                    isRequest={isRequest}
                    requestResData={requestResData}
                    AcceptHandler={AcceptHandler}
                    setParticipants={setParticipants}
                    participants={participants}
                  />
                )}
              </div>
              {eventRoomStore._state.isLive && showChat && (
                <section
                  className={`sidebar-container _right ${"float-chat-right"}`}
                >
                  <button
                    className="close-chat-mobile"
                    onClick={() => handleChatClick()}
                  >
                    <HighlightOffIcon />
                  </button>
                  <section className="chat-container">
                    <ChatMenu handleChat={handleChatClick} />
                  </section>
                </section>
              )}
              {showPopUp && (
                <PopUp
                  handlePopUpClose={handleShowPopUp}
                  toggleConfirmationPopup={toggleConfirmationPopup}
                />
              )}
              {IsConfirmationPopUp && (
                <ConfirmationPopUp
                  toggleConfirmationPopup={toggleConfirmationPopup}
                  handlePopUpClose={handleShowPopUp}
                />
              )}
              {IsFullScreenConfirmation && (
                <FullScreenConfirmation
                  fullScreenElemId="preshow-talks"
                  toggleFullScreenConfirmation={toggleFullScreenConfirmation}
                  handlesideStream={setShowSideStream}
                />
              )}
            </section>
          ) : (
            <>
              {eventRoomState.me.info.role == "invitee" && (
                <PreCallScreenInvitee
                  JoinHandle={() => {
                    // eventRoomStore.hasVisitStarted(true),
                    //   sessionStorage.setItem("visitStarted", "true");
                  }}
                />
              )}
              {(eventRoomState.me.info.role === "residence" ||
                eventRoomState.me.info.role === "guide" ||
                eventRoomState.me.info.role === "host" ||
                eventRoomState.me.info.role === "multiconnection" ||
                eventRoomState.me.info.role === "admin") && (
                <PreCallScreen
                  JoinHandle={() => {
                    {eventRoomState.me.info !== 'residence' &&
                      eventRoomState.me.info !== 'multiconnection' &&
                      (eventRoomStore.hasVisitStarted(true),
                      sessionStorage.setItem("visitStarted", "true"));
                    }
                  }}
                />
              )}
            </>
          )}
          {isCallEnd && (
            <div
              style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                zIndex: 9999
              }}
            >
              <Endscreen />
            </div>
          )}
          {isCallEndMlt && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 9999
              }}
            >
              <Endscreen />
            </div>
          )}
        </>
      </section>
    </>
  );
};

export default PreTalkShow;