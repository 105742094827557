import React, { Fragment, useEffect, useState } from "react";
import useStream from "@/hooks/use-stream";
import "./participants.scss";
import SearchIcon from "@mui/icons-material/Search";
import RemoteAudioControl from "@/components/remote-audio-control/remote-audio-control";
import RemoteVideoControl from "@/components/remote-video-control/remote-video-control";
import AudienceAvatar from "@/assets/audience-avatar.png";
import { useEventRoomState } from "@/containers/root-container";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RemoveParticipants from "./RemoveParticipants";
import CloseIcon from "@mui/icons-material/Close";
import { eventRoomStore } from "@/stores/event";
import { BASE_URL } from "@/utils/config";

const ShowParticipants = (props: any) => {
	const eventRoomState = useEventRoomState();
	const {
		allStreams,
		remoteStreams,
		localStream,
		guideStream,
		InviteeStreams,
	} = useStream(eventRoomStore);
	const [inMeetingVisible, setInMeetingVisible] = useState(true);
	const [waitingVisible, setWaitingVisible] = useState(true);
	const [InviteVisible, setInviteVisible] = useState(true);
	const [searchQuery, setSearchQuery] = useState("");
	const [waitingListData, setWaitingListData] = useState([]);

	useEffect(() => {
		const fetchWaitingMembers = async () => {
			try {
				const response = await fetch(
					`${BASE_URL}api/visits/participants/waiting?visit_id=${eventRoomState.me.channelName}`
				);
				if (!response.ok) {
					throw new Error("Failed to fetch waiting members");
				}
				const data = await response.json();
				setWaitingListData(data.data);
			} catch (error) {
				console.error("Error fetching waiting members:", error);
				// Handle error state or display error message
			}
		};

		fetchWaitingMembers();
	}, [eventRoomState.me.channelName, props.AcceptHandler]);

	const getRoleName = (uid: any) => {
		const index = remoteStreams.findIndex((stream) => stream.streamID === uid);
		if (index !== -1) {
			return remoteStreams[index].userRole;
		}
		return "";
	};

	const toggleInMeetingVisibility = () => {
		setInMeetingVisible(!inMeetingVisible);
	};

	const toggleInviteeVisibility = () => {
		setInviteVisible(!InviteVisible);
	};

	const toggleWaitingVisibility = () => {
		setWaitingVisible(!waitingVisible);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	const filteredParticipants = allStreams.filter(
		(stream) =>
			stream.name &&
			stream.name.toLowerCase().includes(searchQuery.toLowerCase())
			&& (eventRoomState.showAdminVideoPlayer || (!eventRoomState.showAdminVideoPlayer && getRoleName(stream.streamID) != 'admin'))
	);

	const participantsList = filteredParticipants.map((stream) => {
		const roleName = getRoleName(stream.streamID);
		return (
			<li key={stream.streamID}>
				<div className="user-profile-data">
					{stream.profile_pic === "" ? (
						<img src={AudienceAvatar} alt="user-profile-image" />
					) : (
						<img src={stream.profile_pic} alt="user-profile-image" />
					)}
					<p>
						{stream.name} {roleName && `(${roleName})`}
					</p>
				</div>
				{localStream && localStream.streamID !== stream.streamID && (
					<div className="user-actions">
						{eventRoomState.me.info.role !== "multiconnection" && (
							<>
								<RemoteAudioControl
									streamID={stream.streamID}
									audio={stream.audio}
								/>
								<RemoteVideoControl
									streamID={stream.streamID}
									video={stream.videoTurnedOn}
								/>
							</>
						)}
						{eventRoomState.me.info.role !== "multiconnection" &&
							eventRoomState.me.info.role !== "guide" &&
							stream.streamID !== guideStream?.streamID && (
								<RemoveParticipants
									streamID={stream.streamID}
									handleSwtichStream={props.handleSwtichStream}
									liveStreams={props.liveStreams}
								/>
							)}
					</div>
				)}
			</li>
		);
	});

	const waitingList =
		props.requestResData &&
		props.requestResData.map((user: any) => {
			const matchingStream = allStreams.find(
				(stream: any) => stream.streamID === user.user_id
			);
			if (matchingStream) {
				props.isRequest.current = false;
			}

			return (
				<Fragment key={user.user_id}>
					{user.user_role === "invitee" &&
					eventRoomState.me.info.role === "host" ? (
						<li key={user.user_id}>
							<p>{user.user_name}</p>
							<div className="participants-join-request" key={user.user_id}>
								<div className="participants-join-request-button">
									{eventRoomState.me.info.role === "host" && (
										<button
											onClick={() => {
												props.AcceptHandler(
													false,
													eventRoomState.me.channelName,
													user.user_id,
													user.user_name,
													user.user_role
												);
											}}
											className="reject-button"
										>
											Reject
										</button>
									)}
									<button
										onClick={() => {
											props.AcceptHandler(
												true,
												eventRoomState.me.channelName,
												user.user_id,
												user.user_name,
												user.user_role
											);
										}}
										className="accept-button"
									>
										Accept
									</button>
								</div>
							</div>
						</li>
					) : user.user_role !== "invitee" &&
					  eventRoomState.me.info.role !== "residence" ? (
						<li key={user.user_id}>
							<p>{user.user_name}</p>
							<div className="participants-join-request" key={user.user_id}>
								<div className="participants-join-request-button">
									{eventRoomState.me.info.role === "host" && (
										<button
											onClick={() => {
												props.AcceptHandler(
													false,
													eventRoomState.me.channelName,
													user.user_id,
													user.user_name,
													user.user_role
												);
											}}
											className="reject-button"
										>
											Reject
										</button>
									)}
									<button
										onClick={() => {
											props.AcceptHandler(
												true,
												eventRoomState.me.channelName,
												user.user_id,
												user.user_name,
												user.user_role
											);
										}}
										className="accept-button"
									>
										Accept
									</button>
								</div>
							</div>
						</li>
					) : null}
				</Fragment>
			);
		});

	const waitingListMembers =
		waitingListData &&
		waitingListData.map((user: any) => {
			const matchingStream = allStreams.find(
				(stream: any) => stream.streamID === user.user_id
			);
			if (matchingStream) {
				props.isRequest.current = false;
			}

			return (
				<Fragment key={user.user_id}>
					{user.user_role === "invitee" &&
					eventRoomState.me.info.role === "host" ? (
						<li key={user.user_id}>
							<p>{user.user_name}</p>
							<div className="participants-join-request" key={user.user_id}>
								<div className="participants-join-request-button">
									{eventRoomState.me.info.role === "host" && (
										<button
											onClick={() => {
												props.AcceptHandler(
													false,
													eventRoomState.me.channelName,
													user.user_id,
													user.user_name,
													user.user_role
												);
											}}
											className="reject-button"
										>
											Reject
										</button>
									)}
									<button
										onClick={() => {
											props.AcceptHandler(
												true,
												eventRoomState.me.channelName,
												user.user_id,
												user.user_name,
												user.user_role
											);
										}}
										className="accept-button"
									>
										Accept
									</button>
								</div>
							</div>
						</li>
					) : user.user_role !== "invitee" &&
					  eventRoomState.me.info.role !== "residence" ? (
						<li key={user.user_id}>
							<p>{user.user_name}</p>
							<div className="participants-join-request" key={user.user_id}>
								<div className="participants-join-request-button">
									{eventRoomState.me.info.role === "host" && (
										<button
											onClick={() => {
												props.AcceptHandler(
													false,
													eventRoomState.me.channelName,
													user.user_id,
													user.user_name,
													user.user_role
												);
											}}
											className="reject-button"
										>
											Reject
										</button>
									)}
									<button
										onClick={() => {
											props.AcceptHandler(
												true,
												eventRoomState.me.channelName,
												user.user_id,
												user.user_name,
												user.user_role
											);
										}}
										className="accept-button"
									>
										Accept
									</button>
								</div>
							</div>
						</li>
					) : null}
				</Fragment>
			);
		});

	const waitingListMembersFiltered = waitingListMembers.filter(
		(waitingItem: React.ReactElement<any>) => {
			const waitingItemKey = waitingItem.key;
			return !participantsList.some(
				(participantItem) => participantItem.key === waitingItemKey
			);
		}
	);

	const waitingListFiltered = waitingList.filter(
		(waitingItem: React.ReactElement<any>) => {
			const waitingItemKey = waitingItem.key;
			return !participantsList.some(
				(participantItem) => participantItem.key === waitingItemKey
			);
		}
	);

	const filteredInviteeStreams = InviteeStreams.filter((stream: any) => {
		const waitingListKeys = waitingListMembersFiltered.map((item) => item.key);
		const waitingFilteredKeys = waitingListFiltered.map(
			(item: { key: any }) => item.key
		);
		return (
			!waitingListKeys.includes(stream.streamId) &&
			!waitingFilteredKeys.includes(stream.streamId)
		);
	});

	const handelParticipantsInTab = () => {
		props.setParticipants(false);
	};

	return (
		<div className="side-participants">
			<div className="header">
				<h4>Participants</h4>
				<span className="participants-hide" onClick={handelParticipantsInTab}>
					<CloseIcon />
				</span>
			</div>
			<div className="search">
				<SearchIcon />
				<input
					type="text"
					placeholder="Search for participants"
					value={searchQuery}
					onChange={handleSearchChange}
				/>
			</div>
			<div className="meeting-users">
				<h5 onClick={toggleInMeetingVisibility} className="toggle-button">
					In Visit
					{inMeetingVisible ? (
						<KeyboardArrowUpIcon />
					) : (
						<KeyboardArrowDownIcon />
					)}
				</h5>
				<ul
					style={{
						display: inMeetingVisible ? "flex" : "none",
						border: "1px solid #E6C3B5",
					}}
				>
					{participantsList.length > 0 ? (
						participantsList
					) : (
						<li>No data to show</li>
					)}
				</ul>
			</div>
			{eventRoomState.me.info.role === "host" && (
				<div className="waiting-users">
					<h5 onClick={toggleInviteeVisibility} className="toggle-button">
						Invitee
						{InviteVisible ? (
							<KeyboardArrowUpIcon />
						) : (
							<KeyboardArrowDownIcon />
						)}
					</h5>
					<ul
						style={{
							display: InviteVisible ? "flex" : "none",
							border: "1px solid #E6C3B5",
						}}
					>
						{filteredInviteeStreams && filteredInviteeStreams.length > 0 ? (
							<>
								{filteredInviteeStreams &&
									filteredInviteeStreams.map((stream: any) => (
										<li key={stream.streamID}>
											<div className="user-profile-data">
												{stream.profile_pic === "" ? (
													<img src={AudienceAvatar} alt="user-profile-image" />
												) : (
													<img
														src={stream.profile_pic}
														alt="user-profile-image"
													/>
												)}
												<p>{stream.name}</p>
											</div>
										</li>
									))}
							</>
						) : (
							<li>No data to show</li>
						)}
					</ul>
				</div>
			)}
			{eventRoomState.me.info.role !== "multiconnection" && (
				<div className="waiting-users">
					<h5 onClick={toggleWaitingVisibility} className="toggle-button">
						Waiting Outside
						{waitingVisible ? (
							<KeyboardArrowUpIcon />
						) : (
							<KeyboardArrowDownIcon />
						)}
					</h5>
					<ul
						style={{
							display: waitingVisible ? "flex" : "none",
							border: "1px solid #E6C3B5",
						}}
					>
						{waitingListFiltered.length !==
						waitingListMembersFiltered.length ? (
							waitingListMembersFiltered.length > 0 ? (
								waitingListMembersFiltered
							) : (
								<li>No data to show</li>
							)
						) : waitingListFiltered.length > 0 ? (
							waitingListFiltered
						) : (
							<li>No data to show</li>
						)}
					</ul>
				</div>
			)}
		</div>
	);
};

export default ShowParticipants;
