import React from "react";
import {
  GoogleMap,
  Polyline,
  LoadScript,
  OverlayView,
} from "@react-google-maps/api";
import resMapIcon from "@/assets/residence_map_icon.png";
import Guide from "@/assets/guide.png";
import PlaceIcon from "@mui/icons-material/Place";

interface Coordinates {
  residence: google.maps.LatLngLiteral;
  guide: google.maps.LatLngLiteral;
}

const MapContainer: React.FC<{
  coordinates: Coordinates;
  eventDetail: any;
}> = ({ coordinates, eventDetail }) => {
  const mapApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const midpoint = {
    lat: (coordinates.residence.lat + coordinates.guide.lat) / 2,
    lng: (coordinates.residence.lng + coordinates.guide.lng) / 2,
  };

  const ImageHandler = (link: any) => {
    let text = link;
    text = text.replace("file/d/", "uc?id=");
    text = text.split("/view?usp=");
    return text[0];
  };

  return (
    <div style={{ marginTop: 30, marginBottom: 30, height: 300 }}>
      <LoadScript googleMapsApiKey={mapApiKey!}>
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "calc(100% - 30px)" }}
          center={midpoint} // Set the midpoint as the center of the map
          zoom={2.7} // Adjust the zoom level accordingly
        >
          <Polyline
            path={[coordinates.residence, coordinates.guide]}
            options={{
              strokeColor: "#4285F4", // Blue stroke color
              strokeOpacity: 1,
              strokeWeight: 5,
            }}
          />
          <OverlayView
            position={coordinates.guide}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
              x: -(width / 2),
              y: -height,
            })}
          >
            <div className="marker-containerStyle">
              <img
                src={
                  eventDetail?.guide_picture &&
                  eventDetail?.guide_picture.includes("http")
                    ? ImageHandler(eventDetail.guide_picture)
                    : Guide
                }
                alt="Guide"
              />
            </div>
          </OverlayView>

          <OverlayView
            position={coordinates.residence}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
              x: -(width / 2),
              y: -height,
            })}
          >
            <div className="marker-containerStyle">
              <img src={resMapIcon} alt="Residence" />
            </div>
          </OverlayView>

          {/* Marker for residence location */}
          {/* <Marker
            position={coordinates.residence}
            icon={{
              url: resMapIcon, // Icon for residence location
            }}
          /> */}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapContainer;
