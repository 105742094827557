import { ChatCmdType, MessageBody } from "@/utils/agora-rtm-client";
import EventEmitter from "events";
import {
  BASE_URL,
  MAX_USERS_STREAMS,
  QUERY_STRING_SEPARATOR,
  INJECT_STREAM_ID,
  SECOND_INJECT_STREAM_ID,
  SCREEN_SHARE_STREAM_ID,
} from "@/utils/config";
import { abbreviateNumber } from "@/utils/helper";
import AgoraRTC from "agora-rtc-sdk-ng";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  AgoraStream,
  AgoraMediaStream,
  ChatMessage,
  AgoraClient,
} from "@/utils/types";
import { Subject } from "rxjs";
import { Map, Set, List } from "immutable";
import { get, set } from "lodash";
import AgoraRTMClient, { ChannelParams, agoraRTMClient } from "@/utils/agora-rtm-client";
import { AgoraWebClient } from "@/utils/agora-web-client";
import { APP_ID } from "@/utils/config";
import Axios from "axios";
import { ApiUrls } from "@/utils/api/constant/api.constant";

export interface AgoraUser {
  uid: any;
  role: number;
  video: number;
  audio: number;
}

export interface Me extends AgoraUser {
  channelName: string;
  publishLocalStream?: boolean;
  mainLiveStreamID?: number; // this is only used when we are are live show screen to handle default main stream.
  info?: any;
}

type RtcState = {
  published: boolean;
  joined: boolean;
  users: Set<number>;
  localStream: AgoraMediaStream | null;
  remoteStreams: Map<string, AgoraMediaStream>;
  injectedStream?: AgoraMediaStream | null;
  injected: boolean; // rtmp injection by host
  isAutoPlayFailed: boolean;
};

export type MediaDeviceState = {
  microphoneId: string;
  speakerId: string;
  cameraId: string;
  speakerVolume: number;
  camera: number;
  microphone: number;
  speaker: number;
};

export type RtmState = {
  joined: boolean;
};

export type EventRoomState = {
  appID: string;
  rtcToken: string;
  me: Me;
  usersAsAudience: Array<Object>;
  rtc: RtcState;
  rtm: RtmState;
  mediaDevice: MediaDeviceState;
  messages: List<ChatMessage>;
  privateMessages: List<ChatMessage>;
  viewerCount: number;
  isLive: boolean;
  networkQuality: number;
  currentMicrophone: any;
  currentCamera: any;
  currentSpeaker: any;
  gridView: boolean;
  hasStarted: boolean;
  is_residence_silent: boolean;
  is_noise_cancellation_on: boolean;
  is_technical_visit: boolean;
  pinId: any;
  isMessageSent: boolean;
  waitingList: any;
  hasJoinedVisit: boolean;
  showAdminVideoPlayer: boolean;
};

export class EventRoomStore {
  private history: any;
  setHistory(history: any) {
    this.history = history;
  }
  private subject: Subject<EventRoomState> | null;
  public _state: EventRoomState;

  get state() {
    return this._state;
  }

  set state(newState) {
    this._state = newState;
  }
  public readonly defaultState: EventRoomState = Object.freeze({
    appID: APP_ID,
    rtcToken: "",
    me: {
      uid: 0,
      role: 1,
      video: 1,
      audio: 1,
      channelName: "",
      publishLocalStream: false,
      mainLiveStreamID: 0,
      info: {
        uid: 0,
        name: "",
        role: "",
        profile_pic: "",
        guide: {
          id: 0,
          name: "",
          profile_pic: "",
          profile_url: "",
          userRole: "",
        },
      },
      host: {
        id: 0,
        name: "",
        userRole: "",
      },
      residence: {
        id: 0,
        name: "",
        userRole: "",
      },
      invitees: {
        id: 0,
        name: "",
        userRole: "",
      },
      admin: {
        id: 0,
        name: "",
        userRole: "",
      },
    },
    usersAsAudience: Array<Object>(),
    hasJoinedVisit: false,
    showAdminVideoPlayer: true,
    isLive: true,
    networkQuality: 0,
    is_residence_silent: true,
    is_noise_cancellation_on: true,
    is_technical_visit: false,
    rtm: {
      joined: false,
    },
    rtc: {
      published: false,
      joined: false,
      users: Set<number>(),
      localStream: null,
      remoteStreams: Map<string, AgoraMediaStream>(),
      injectedStream: null,
      injected: false,
      isAutoPlayFailed: true,
    },
    mediaDevice: {
      microphoneId: "",
      speakerId: "",
      cameraId: "",
      speakerVolume: 100,
      camera: 0,
      speaker: 0,
      microphone: 0,
    },
    currentMicrophone: "",
    currentCamera: "",
    currentSpeaker: "",
    messages: List<ChatMessage>(),
    privateMessages: List<ChatMessage>(),
    isMessageSent: false,
    viewerCount: 0,
    gridView: false,
    hasStarted: Boolean(sessionStorage.getItem("visitStarted")) || false,
    pinId: 0,
    waitingList: [],
  });

  public rtmClient: AgoraRTMClient;
  public rtcClient: AgoraWebClient;
  public rtcFanWallClients: AgoraClient[];
  public _emitter: EventEmitter;

  constructor() {
    this.subject = null;
    this._state = {
      ...this.defaultState,
    };
    this.rtmClient = agoraRTMClient;
    this.rtcClient = new AgoraWebClient({ eventRoomStore: this });
    this.rtcFanWallClients = [];
    this._emitter = new EventEmitter();
  }

  initialize() {
    this.subject = new Subject<EventRoomState>();
    this.state = {
      ...this.defaultState,
    };
    this.subject.next(this.state);
  }

  subscribe(updateState: any) {
    this.initialize();
    this.subject && this.subject.subscribe(updateState);
  }

  unsubscribe() {
    this.subject && this.subject.unsubscribe();
    this.subject = null;
  }

  commit(state: EventRoomState) {
    this.subject && this.subject.next(state);
  }

  updateState(rootState: EventRoomState) {
    this.state = {
      ...this.state,
      ...rootState,
    };
    this.commit(this.state);
  }

  addLocalStream(stream: AgoraStream) {
    this.updateLocalStream(stream);
    console.log("Add local stream ?????????: ", stream)
  }

  updateAutoPlayFailedStatus(flag: boolean) {
    this.state = {
      ...this.state,
      rtc: {
        ...this.state.rtc,
        ["isAutoPlayFailed"]: flag,
      },
    };
    this.commit(this.state);
  }

  updateAdminVideoPlayerStatus(flag: boolean) {
    this.state = {
      ...this.state,
      showAdminVideoPlayer: flag
    };
    this.commit(this.state);
  }

  addInjectedStream(stream: AgoraStream, key: string = "rtc") {
    this.updateLocalStream(stream, "injectedStream");
  }

  handleMainExit(role: any, redirect_url: any) {
    this.rtmClient.exit();
    sessionStorage.removeItem("visitStarted");
    window.location.href = redirect_url;
  }

  removeLocalStream(stream: any = null, key: string = "localStream") {
    try {
      const localStream =
        key === "localStream" ? this.state.rtc.localStream : "";
      if (
        localStream &&
        localStream.stream.videoTrack &&
        localStream.stream.videoTrack.isPlaying()
      ) {
        //localStream.stream.stop();
      }
      this.updateLocalStream(stream, key);
    } catch (e) { }
  }

  removeInjectedStream = () => {
    this.state.rtc.injectedStream
      ? this.state.rtc.injectedStream.stream?.videoTrack.stop()
      : null;
    this.updateLocalStream(null, "injectedStream");
  };

  updateLocalStream(stream: any = null, key: string = "localStream") {
    this.state = {
      ...this.state,
      rtc: {
        ...this.state.rtc,
        [key]: stream,
      },
    };
    this.commit(this.state);

    console.log("Updating local stream ??????: ", this.state)
  }
  setPinId = (value: number) => {
    this.state = {
      ...this.state,
      pinId: value,
    };
    this.commit(this.state);
  };

  handleRemotePin = (memberId: any) => {
    let Pindata = {
      type: ChatCmdType.pinid,
      msgData: { streamID: memberId },
    };
    this.rtmClient.sendChannelMessage(Pindata, this.state.me.channelName);
  };

  addRTCUser(uid: number, key: string = "rtc") {
    const prevState = this.state.rtc;
    this.state = {
      ...this.state,
      [key]: {
        ...prevState,
        users: prevState.users.add(uid),
      },
    };
    this.commit(this.state);
  }

  removePeerUser(uid: number, key: string = "rtc") {
    const prevState = this.state.rtc;
    this.state = {
      ...this.state,
      [key]: {
        ...prevState,
        users: prevState.users.delete(uid),
      },
    };
    this.commit(this.state);
  }

  addRemoteStream(stream: AgoraStream, key: string = "rtc") {
    const prevState = this.state.rtc;
    this.state = {
      ...this.state,
      [key]: {
        ...prevState,
        remoteStreams: prevState.remoteStreams.set(
          `${stream.streamID}`,
          stream
        ),
      },
    };

    this.commit(this.state);

    console.log("Adding remote stream ?????: ", this.state)
  }

  removeRemoteStream(uid: number, key: string = "rtc") {
    const prevState = this.state.rtc;
    const remoteStream = prevState.remoteStreams.get(`${uid}`);

    this.state = {
      ...this.state,
      [key]: {
        ...prevState,
        remoteStreams: prevState.remoteStreams.delete(`${uid}`),
      },
    };
    if (
      typeof remoteStream?.stream.audioTrack != "undefined" &&
      remoteStream?.stream.audioTrack != null
    ) {
      remoteStream ? remoteStream.stream?.audioTrack?.stop() : null;
    }
    if (
      typeof remoteStream?.stream.videoTrack != "undefined" &&
      remoteStream?.stream.videoTrack != null
    ) {
      remoteStream ? remoteStream.stream?.videoTrack.stop() : null;
    }
    this.commit(this.state);
  }

  updateDevice(state: MediaDeviceState) {
    this.state = {
      ...this.state,
      mediaDevice: state,
    };
    this.commit(this.state);
  }

  setMeAttr(key: string, uid: number) {
    this.state = {
      ...this.state,
      me: {
        ...this.state.me,
        [key]: uid,
      },
    };
    this.commit(this.state);
  }

  setRTCToken(token: string) {
    this.state = {
      ...this.state,
      rtcToken: token,
    };
    this.commit(this.state);
  }

  updateWaitingList(waitinglist: any) {
    this.state = {
      ...this.state,
      waitingList: waitinglist,
    };
    this.commit(this.state);
  }

  setRTCJoined(joined: boolean) {
    this.state = {
      ...this.state,
      rtc: {
        ...this.state.rtc,
        joined: joined,
      },
    };
    this.commit(this.state);
  }

  updateChatMessage(msg: ChatMessage) {
    this.state = {
      ...this.state,
      messages: this.state.messages.push(msg),
      isMessageSent: this.state.isMessageSent ? true : false,
    };

    this.commit(this.state);
  }

  updatePrivateChatMessage(msg: ChatMessage) {
    this.state = {
      ...this.state,
      privateMessages: this.state.privateMessages.push(msg),
      isMessageSent: this.state.isMessageSent ? true : false,
    };

    this.commit(this.state);
  }

  updateGridView(flag: boolean) {
    this.state = {
      ...this.state,
      gridView: flag,
    };
    this.commit(this.state);
    if (flag) {
      eventRoomStore.setMeAttr("mainLiveStreamID", 0);
    }
  }

  updateNoiseCancellation(flag: boolean) {
    this.state = {
      ...this.state,
      is_noise_cancellation_on: flag,
    };
    this.commit(this.state);
  }

  updateSilentResidenceFeature(flag: boolean) {
    this.state = {
      ...this.state,
      is_residence_silent: flag,
    };
    this.commit(this.state);
  }

  hasVisitStarted(flag: boolean) {
    this.state = {
      ...this.state,
      hasStarted: flag,
    };
    this.commit(this.state);
  }

  async sendMessage(data: ChannelParams) {
    try {
      if (!this.state.rtm.joined) return;
      await this.rtmClient.sendChannelMessage(data, this.state.me.channelName);

      if (data.type === ChatCmdType.chat) {
        this.updateChatMessage(data.msgData);
      }
    } catch (e) { }
  }

  async sendPrivateMessage(uid: string, data: MessageBody) {
    try {
      if (!this.state.rtm.joined) return;
      await this.rtmClient.sendPeerMessage(
        uid.toString(),
        data,
        this.state.me.channelName
      );

      if (data.type === ChatCmdType.chat) {
        this.updatePrivateChatMessage(data.msgData);
      }
    } catch (e) {
      console.error("Error sending peer message:", e);
    }
  }

  async exitAll() {
    try {
      this.rtcClient.exit();
      this.rtmClient.exit();
      let noStreamElem = document.querySelector(
        ".large-class .main-stream-section .main-stream.no-video"
      ) as HTMLElement;
      if (noStreamElem != null) {
        noStreamElem.style.backgroundColor = "transparent";
      }
    } catch (err) {
      console.warn(err);
    }
    finally {
    }
  }

  async highlightStreamSpeaking() {
    try {
      await this.rtcClient.volumeHighlights();
      console.log("Volume indicator initialized successfully");
    } catch (err) {
      console.warn(err);
    }
  }

  private compositeMe(params: Partial<Me>): Me {
    const newMe: Me = { ...this.state.me };
    for (const prop in params) {
      if (newMe.hasOwnProperty(prop) && params.hasOwnProperty(prop)) {
        set(newMe, prop, get(params, prop, ""));
      }
    }
    return newMe;
  }

  async updateLocalMe(params: Partial<Me>) {
    const { ...meParams } = params;
    const newMe = this.compositeMe(meParams);

    this.state = {
      ...this.state,
      me: {
        ...this.state.me,
        ...newMe,
      },
    };
    this.commit(this.state);
  }

  async mute(uid: string, type: string) {
    console.log("Mute ???????")
    const me = this.state.me;
    if (`${me.uid}` === `${uid}`) {
      const streamType = "localStream";
      const localStream: any = this.state.rtc[streamType];
      if (localStream) {
        localStream[type] = false;
        this.updateLocalStream(localStream, streamType);
      }
    } else {
      const remoteStream: any = this.state.rtc.remoteStreams.get(`${uid}`);
      if (remoteStream) {
        remoteStream[type] = false;
        this.addRemoteStream(remoteStream);
      } else {
      }
    }
  }

  async updateRemoteDetails(
    type: string,
    streamID: any,
    track: any,
    details: any
  ) {
    const name = details?.name;
    const prevState = this.state.rtc;
    let remoteStream = prevState.remoteStreams.get(`${streamID}`) as any;
    let key = "remoteStream";

    if (typeof remoteStream == "undefined") {
      return;
    }

    remoteStream["stream"][type] = track;
    if (type == "videoTrack") {
      remoteStream["video"] = true;
      remoteStream["videoTurnedOn"] = true;
    } else {
      remoteStream["audio"] = true;
    }
    remoteStream["name"] = name;

    if (key == "injectedStream") {
      this.state = {
        ...this.state,
        ["rtc"]: {
          ...prevState,
          injectedStream: remoteStream,
        },
      };
    } else {
      this.state = {
        ...this.state,
        ["rtc"]: {
          ...prevState,
          remoteStreams: prevState.remoteStreams.set(
            `${streamID}`,
            remoteStream
          ),
        },
      };
    }

    this.commit(this.state);
  }

  async unmute(uid: string, type: string) {
    const me = this.state.me;
    if (`${me.uid}` === `${uid}`) {
      const streamType = "localStream";
      const localStream: any = this.state.rtc[streamType];
      localStream[type] = true;
      this.updateLocalStream(localStream, streamType);
    } else {
      const remoteStream: any = this.state.rtc.remoteStreams.get(`${uid}`);
      if (remoteStream) {
        remoteStream[type] = true;
        this.addRemoteStream(remoteStream);
      } else {
      }
    }
  }

  async loginAndJoin(token: string) {
    const { uid, channelName } = this.state.me;

    try {
      await this.rtmClient.login(APP_ID, uid.toString(), token);
      let res = await this.rtmClient.join(channelName);
      const viewerCount = await this.getViewerCount([channelName]);
      const formatedNumber = abbreviateNumber(viewerCount) as any;
      const usersCount = formatedNumber[channelName];
      console.log("viewerCount", viewerCount);
      this.state = {
        ...this.state,
        rtm: {
          ...this.state.rtm,
          joined: true,
        },
        viewerCount: viewerCount,
      };
      this.commit(this.state);
      return;
    } catch (err) {
      if (this.rtmClient._logged) {
        await this.rtmClient.logout();
      }
      throw err;
    }
  }

  async getViewerCount(channel: string[]) {
    try {
      const viewerCount = await this.rtmClient.getChannelMemberCount(channel);
      return viewerCount;
    } catch (e) { }
  }

  updateViewerCount = (count: number) => {
    this.state = {
      ...this.state,
      viewerCount: count,
    };
    this.commit(this.state);
  };

  async getMembersList(channel: string) {
    try {
      if (!channel) return;
      const res: string[] = await this.rtmClient.getChannelMembersList(channel);

      const membersList = Set(res);
      const updatedMembersList = this.filterMembersList(res);
      this.state = {
        ...this.state,
        usersAsAudience: updatedMembersList,
      };
      this.commit(this.state);
    } catch (e) { }
  }

  filterMembersList = (membersList: any) => {
    const remoteUsers = this.state.rtc.users;
    let updatedMemberList: any = membersList;
    updatedMemberList = updatedMemberList.filter((members: any) =>
      this.state.me.info.invitees.some(
        (invitee: any) => invitee.id.toString() === members.userId.toString()
      )
    );
    return updatedMemberList;
  };

  handleStreamControlClick = (type: string, active: boolean) => {
    const me = this.state.me;
    if (!me.uid) return console.warn("please confirm joined rtc");
    const targetUser = me;
    if (!targetUser) return;
    const targetUid = String(targetUser.uid);
    if (type == "video" || "videoTurnedOn" || type == "audio") {
      if (active) {
        this.mute(targetUid, type);
      } else {
        this.unmute(targetUid, type);
      }
    }
  };

  handleRemoteAudio = (memberId: any, status: any) => {
    let audioData = {
      type: ChatCmdType.muteAudio,
      msgData: {audio: status === "unmute" ? true: false, streamId: memberId},
    };
    if (status === "unmute") {
      audioData.type = ChatCmdType.unmuteAudio;
    }
    
    eventRoomStore.sendMessage(audioData);
  };

  handleResidenceSilent = (memberId: any) => {
    let SilentData = {
      type: ChatCmdType.isSilent,
      msgData: {},
    };

    this.rtmClient.sendPeerMessage(
      memberId.toString(),
      SilentData,
      this.state.me.channelName
    );
  };

  handleRemoteVideo = (memberId: any, status: any) => {
    let cmd = ChatCmdType.muteVideo;
    let videoStatus = false;
    if(status === "mute") {
      eventRoomStore.mute(memberId.toString(), "video");
      eventRoomStore.mute(memberId.toString(), "videoTurnedOn");
    } else {
      videoStatus = true;
      cmd = ChatCmdType.unmuteVideo;
      eventRoomStore.unmute(memberId.toString(), "video");
      eventRoomStore.unmute(memberId.toString(), "videoTurnedOn");
    }

    let videoData = {
      cmd,
      data: {
        streamId: memberId,
        video: videoStatus
      },
    };
    // if (status == "unmute") {
    //   videoData.cmd = ChatCmdType.unmuteVideo;
    // }
    eventRoomStore.sendMessage(
      {
        type: videoData.cmd,
        msgData: videoData.data,
      }
    );
  };

  handleRemoteAudioForAll = (status: any) => {
    let audioData = {
      type: ChatCmdType.muteAudio,
      msgData: { streamID: eventRoomStore.state.me.uid, type: "audio" },
    };

    if (status == "unmute") {
      audioData.type = ChatCmdType.unmuteAudio;
    }
  };

  handleRemoteVideoForAll = (status: any) => {
    let videoData = {
      type: ChatCmdType.muteVideo,
      msgData: { streamID: eventRoomStore.state.me.uid, type: "video" },
    };

    if (status == "unmute") {
      videoData.type = ChatCmdType.unmuteVideo;
    }

  };

  setNetworkQuality = (value: number) => {
    this.state = {
      ...this.state,
      networkQuality: value,
    };
    this.commit(this.state);
  };

  getAgoraTokens = async (
    channelName: any,
    userId: any,
    type: string = "rtc",
    isScreenShareToken?: boolean
  ) => {
    try {
      const res = await Axios.post(ApiUrls.AgoraUrl, {
        channelName,
        userId: `${userId}`,
        type,
        isScreenShareToken
      });
      const { rtcToken, rtmToken } = res?.data?.data;
      return type == "rtc" ? rtcToken : rtmToken;
    } catch (e: any) {
      console.log(e)
      throw new Error(e);
    }
  };
  // const res = await Axios.post(ApiUrls.AgoraUrl, {
  //   channelName,
  //   userId: `${userId}`,
  //   type
  // });
  // const { rtcToken, rtmToken } = res?.data?.data;
  // return type == "rtc" ? rtcToken : rtmToken;
  // };

  getUserNameById = (userId: any) => {
    let userName = "";
    console.log("Invitee search", this.state.me.info?.invitees);
    console.log("Invitee search id", userId);
    if (userId == this.state.me.info?.guide?.id) {
      userName = this.state.me.info?.guide.name;
    } else if (userId == this.state.me.info?.host?.id) {
      userName = "Equipe Travel Me";
    } else if (userId == this.state.me.info?.residence?.id) {
      userName = this.state.me.info?.residence.name;
    } else if (userId == this.state.me.info?.admin?.id) {
      userName = this.state.me.info?.admin.name;
    } else {
      for (let UserId of this.state.me.info?.invitees) {
        console.log("invitees Id", UserId);
        if (UserId.id == `${userId}`) {
          console.log("inside if invitees Id", UserId);
          userName = UserId.name;
        }
      }
    }
    return userName;
  };

  getUserRoleById = (userType: any) => {
    console.log("getUserRoleById", userType);
    let user_role = "";
    if (userType == this.state.me.info?.guide.id) {
      user_role = this.state.me.info?.guide.userRole;
    } else if (userType == this.state.me.info?.host.id) {
      user_role = this.state.me.info?.host.userRole;
    } else if (userType == this.state.me.info?.residence.id) {
      user_role = this.state.me.info?.residence.userRole;
    } else if (userType == this.state.me.info?.admin?.id) {
      user_role = this.state.me.info?.admin.userRole;
    } else if (this.state.me.info?.invitees) {
      for (let UserId of this.state.me.info?.invitees) {
        console.log("invitees Id", userType);
        if (UserId.id == userType) {
          user_role = "invitee";
        }
      }
    }
    return user_role || "multiconnection";
  };

  addRemoteStreamDetails = (
    streamID: any,
    stream: any,
    details: any,
    userRole: any
  ) => {
    const _stream = new AgoraStream(
      stream,
      streamID,
      false,
      false,
      false,
      false,
      details && details?.name ? details?.name : "",
      "",
      userRole
    );
    if (+streamID === INJECT_STREAM_ID) {
      eventRoomStore.setMeAttr("mainLiveStreamID", 0);
      sessionStorage.setItem("injected", "priority");
      this.updateGridView(false);
      this.addInjectedStream(_stream);
      console.log("adding stream id", streamID);
    } else {
      this.addRTCUser(streamID);
      if (streamID === SCREEN_SHARE_STREAM_ID) {
        this.updateGridView(false);
        eventRoomStore.setMeAttr("mainLiveStreamID", 0);
        sessionStorage.removeItem("injected");
      }
      this.addRemoteStream(_stream);

      console.log("stream id is ", streamID);
    }
  };

  updateCurrentMicrophone = (microphoneId: any) => {
    this.state = {
      ...this.state,
      currentMicrophone: microphoneId,
    };
    this.commit(this.state);
  };

  updateCurrentCamera = (cameraId: any) => {
    this.state = {
      ...this.state,
      currentCamera: cameraId,
    };
    this.commit(this.state);
  };

  updateCurrentSpeaker = (speakerId: any) => {
    this.state = {
      ...this.state,
      currentSpeaker: speakerId,
    };
    this.commit(this.state);
  };

  handleDeviceChange = () => {
    AgoraRTC.onMicrophoneChanged = async (changedDevice: any) => {
      if (changedDevice.state != "ACTIVE") {
        const oldMicrophones = await AgoraRTC.getMicrophones();
        if (oldMicrophones[0]) {
          if (
            typeof this.state.rtc.localStream?.stream.audioTrack !=
            "undefined" &&
            this.state.rtc.localStream?.stream.audioTrack != null
          ) {
            this.state.rtc.localStream?.stream.audioTrack.setDevice(
              oldMicrophones[0].deviceId
            );
            window.sessionStorage.setItem(
              "microphoneId",
              oldMicrophones[0].deviceId
            );
            this.updateCurrentMicrophone(oldMicrophones[0].deviceId);
          }
        }
      }
    };

    AgoraRTC.onCameraChanged = async (changedDevice: any) => {
      if (changedDevice.state != "ACTIVE") {
        const oldCameras = await AgoraRTC.getCameras();
        if (oldCameras[0]) {
          if (
            typeof this.state.rtc.localStream?.stream.videoTrack !=
            "undefined" &&
            this.state.rtc.localStream?.stream.videoTrack != null
          ) {
            this.state.rtc.localStream?.stream.videoTrack.setDevice(
              oldCameras[0].deviceId
            );
            window.sessionStorage.setItem("cameraId", oldCameras[0].deviceId);
            this.updateCurrentCamera(oldCameras[0].deviceId);
          }
        }
      }
    };

    AgoraRTC.onPlaybackDeviceChanged = async (changedDevice: any) => {
      if (changedDevice.state != "ACTIVE") {
        const playBackDevices = await AgoraRTC.getPlaybackDevices();
        if (playBackDevices[0]) {
          if (
            typeof this.state.rtc.localStream?.stream.videoTrack !=
            "undefined" &&
            this.state.rtc.localStream?.stream.videoTrack != null
          ) {
            this.state.rtc.localStream?.stream.audioTrack.setPlaybackDevice(
              playBackDevices[0].deviceId
            );
            window.sessionStorage.setItem(
              "speakerId",
              playBackDevices[0].deviceId
            );
            this.updateCurrentSpeaker(playBackDevices[0].deviceId);
          }
        }
      }
    };
  };

  ExitFullScreenMode = () => {
    try {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement
      ) {
        let rightSideElement = document.querySelector(
          "section.sidebar-container._right"
        ) as any;
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
      return "success";
    } catch (err) {
      return "error";
    }
  };

  convertTimeFromUTC = (date: any) => {
    return new Date(date);
  };

  canStartVisit = (timeinUtc: any) => {
    const visit_date: any = this.convertTimeFromUTC(timeinUtc);
    const today: any = new Date();
    const differenceMinutes = (visit_date.getTime() - today.getTime()) / 60000;
    if (differenceMinutes < 20) {
      return true;
    } else {
      return false;
    }
  };

  fetchVisitDetails = async (id: any) => {
    const response = { status: 0, visit: "" };
    try {
      let options = {};
      if (Cookies.get("token")) {
        options = {
          headers: { Authorization: Cookies.get("token") },
        };
      }
      const res = await Axios.get(ApiUrls.visitDetailUrl(id), options);
      response.status = res?.status;
      response.visit = res?.data?.data;
    } catch (e: any) {
      response.status = e?.response?.status;
    }
    return response;
  };

  fetchadminvisitDetails = async (id: any, limit: any, page: any) => {
    const response = { status: 0, visit: "", total: 0 };
    try {
      let options = {};
      if (Cookies.get("token")) {
        options = {
          headers: { Authorization: Cookies.get("token") },
        };
      }
      const res = await Axios.get(ApiUrls.adminvisitDetailUrl(id, limit, page));
      response.status = res?.status;
      response.visit = res?.data?.data;
      response.total = res?.data?.total;
    } catch (e: any) {
      response.status = e?.response?.status;
    }
    return response;
  };

  fetchUniqueId = async () => {
    try {
      const res = await Axios.get(ApiUrls.UniqueIdGenerator);
      if (res.status === 200) {
        return res.data?.data;
      }
    } catch (e: any) { }
  };

  fetchUserDetails = async (
    visit_id: any = this._state.me.channelName,
    user_id: any
  ) => {
    try {
      const res = await Axios.get(ApiUrls.UserDetails(visit_id, user_id));
      console.log("UserDetails", res);
      if (res.status === 200) {
        return res.data.data.user_name;
      } else {
        return "";
      }
    } catch (e: any) { }
  };

  SubmitParticipantdetails = async (
    visit_id: any,
    user_name: any,
    user_id: any,
    user_role: any
  ) => {
    try {
      this.state.me.info.name = user_name;
      const res = await Axios.post(ApiUrls.Particiapntdetails, {
        visit_id: visit_id,
        user_name: user_name,
        user_id: String(user_id),
        link: ApiUrls.Particiapntdetails,
        user_role: user_role,
      });
      console.log("SubmitParticipantdetails", res);
      if (res.status === 200) {
        return res.data?.data;
      }
    } catch (e: any) { }
  };

  logout = () => {
    Cookies.remove("token");
    sessionStorage.clear();
    this.history.push("/");
  };

  handleDeviceError = () => {
    toast.error(
      this.state.me.info.role == "guide"
        ? "Your audio or video device is not detected."
        : "Votre périphérique audio ou vidéo n'est pas détecté."
    );
  };

  async getAgoraVideoProfile() {
    if (this.state.me.info.role == "guide") {
      try {
        const res = await Axios.get(ApiUrls.agoraVideoResolution);
        if (res?.data?.data?.web) {
          return res?.data?.data?.web;
        } else {
          return "1080p_5";
        }
      } catch (e) {
        return "1080p_5";
      }
    } else {
      return "480p_1";
    }
  }

  getAgoraLowStreamProfile = () => {
    if (this.state.me.info.role == "guide") {
      return [640, 480, 15, 500];
    } else {
      return [640, 360, 15, 400];
    }
  };

  fetchGuideVisits = async (page: any, limit: any) => {
    const response = { status: 0, visits: [] };
    const token = Cookies.get("token");
    try {
      const res = await Axios.get(ApiUrls.guidelistUrl(page, limit), {
        headers: { Authorization: token },
      });
      response.status = res?.status;
      response.visits = res?.data?.data ? res?.data?.data : [];
    } catch (e: any) {
      response.status = e?.response?.status;
      if (!response.status) {
        if (e?.message?.includes("401")) {
          response.status = 401;
        }
      }
    }
    return response;
  };
}

export const eventRoomStore = new EventRoomStore();

// TODO: Please remove it before release in production
// 备注：请在正式发布时删除操作的window属性
//@ts-ignore
window.eventRoomStore = eventRoomStore;