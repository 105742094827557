export enum ESocketEvents {
  Connect = "connect",
  Disconnect = "disconnect",
  JoinVisit = "join-visit",
  LeaveVisit = "leave-visit",
  StartVisit = "start-visit",
  Record = "record",
  EndVisit = "end-visit",
  GUIDE_LEAVE = "guide-leave",
  IS_RESIDENCE_SILENT = "is-residense-silent",
  ACCESS_WAIT = "access-wait",
  ACCESS_ACCEPT = "access-accept",
  IS_NOISE_CANCELLATION_ON = "is_noise_cancellation_on",
  REQUEST_CANCEL = "request-cancel",
  ADMIN_TILE = "admin-tile",
}
