import React, { useEffect, useState } from "react";
import Place from "@/assets/place.png";
import Guide from "@/assets/guide.png";
import "./welcome.scss";
import { PageHeader } from "@/components/header/header";
import { useHistory } from "react-router-dom";
import { formatDay, formatTime } from "@/utils/helper";
import Footer from "../Footer/Footer";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";
import WaitingRoom from "../WaitingRoom/Waitingroom";
import { toast } from "react-toastify";
import WelcomeLoader from "../WelcomeLoader/WelcomeLoader";
import { eventRoomStore } from "../../stores/event";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { globalStore } from "@/stores/global";
import MapContainer from "@/components/MapContainer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import HomeIcon from "@mui/icons-material/Home";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";

const Welcome = (props: any) => {
  globalStore.stopLoading();
  const { addSocketEvent, initializeSocket, emitEvent } = useSocket();
  const history = useHistory();
  const eventDetail = props?.visit as any;
  const visit = props?.visit_id;
  const eventid = eventDetail?.id;
  const user_type = eventDetail?.user_role;
  const [visitEnabled, setVisitEnabled] = useState(
    eventDetail?.visit_started ? eventDetail?.visit_started : false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setwaiting] = useState(false);

  const JoinHandler = (time: any) => {
    if (user_type == "host") {
      if (eventRoomStore.canStartVisit(time)) {
        history.push(`/meeting/${visit}`);
      } else {
        toast.error("Vous n'êtes pas autorisé à rejoindre avant 20 minutes", {
          autoClose: 8000,
        });
      }
    } else {
      if (eventRoomStore.canStartVisit(time)) {
        setwaiting(true);
        setTimeout(() => {
          history.push(`/meeting/${visit}`);
        }, 5000);
      } else {
        toast.error(
          "Votre voyage culturel n'a pas encore commencé. Veuillez actualiser la page plus tard.",
          {
            autoClose: 8000
          }
        );
      }
    }
  };

  useEffect(() => {
    if(user_type === 'residence' || user_type === 'multiconnection' || user_type === 'invitee') {
      sessionStorage.clear();
    }
  }, [user_type])

  // const JoinHandler = (time: any) => {
  //   if (user_type == "host") {
  //     if (eventRoomStore.canStartVisit(time)) {
  //       history.push(`/meeting/${visit}`);
  //     } else {
  //       toast.error("Vous n'êtes pas autorisé à rejoindre avant 20 minutes");
  //     }
  //   } else {
  //       setwaiting(true);
  //       setTimeout(() => {
  //         history.push(`/meeting/${visit}`);
  //       }, 5000);
  //   }
  // };

  const ImageHandler = (link: any) => {
    let text = link;
    text = text.replace("file/d/", "uc?id=");
    text = text.split("/view?usp=");
    return text[0];
  };

  useEffect(() => {
    //VisitDetails(eventid);
    initializeSocket();
    emitEvent(ESocketEvents.JoinVisit, {
      visit_id: eventid,
      user_role: user_type,
    });
    addSocketEvent(ESocketEvents.StartVisit, (visitId) => {
      if (eventid == visitId) {
        !visitEnabled ? setVisitEnabled(true) : null;
      }
    });
  }, []);

  const residenceLat = eventDetail.residence_latitude;
  const residenceLng = eventDetail.residence_longitude;

  // Gurgaon Coordinates
  const guideLat = eventDetail.guide_latitude;
  const guideLng = eventDetail.guide_longitude;

  const filteredParticipants = eventDetail.participants.filter(
    (participant: any) => participant.user_role === "MultiConnection"
  );

  return (
    <>
      {isWaiting ? (
        <WaitingRoom />
      ) : (
        <div className="welcome">
          <PageHeader
            user_type={user_type}
            ShowOffer={true}
            Showcontact={true}
          />
          <div
            className={
              user_type === "residence" ? "welcome-page" : "welcome-page-host"
            }
          >
            {isLoading ? (
              <WelcomeLoader />
            ) : (
              <>
                <div className="page-container">
                  <div className="page-main">
                    <div
                      className={
                        user_type === "residence" || user_type === "multiconnection"
                          ? "page-left-side"
                          : "page-left-side-host"
                      }
                    >
                      <img
                        src={
                          eventDetail?.visit_picture &&
                          eventDetail?.visit_picture.includes("http")
                            ? ImageHandler(eventDetail.visit_picture)
                            : Place
                        }
                      />
                    </div>
                    {user_type === "residence" && (
                      <MapContainer
                        coordinates={{
                          residence: { lat: residenceLat, lng: residenceLng },
                          guide: { lat: guideLat, lng: guideLng },
                        }}
                        eventDetail={eventDetail}
                      />
                    )}
                    {user_type === "multiconnection" && (
                      <MapContainer
                        coordinates={{
                          residence: { lat: residenceLat, lng: residenceLng },
                          guide: { lat: guideLat, lng: guideLng },
                        }}
                        eventDetail={eventDetail}
                      />
                    )}
                  </div>
                  <div className="page-main-right">
                    <div className="page-main-right-container">
                      <div className="welcome-header">
                        {user_type === "host" ? (
                          <div className="welcome-header-text">
                            Voyage culturel
                          </div>
                        ) : (
                          <div className="welcome-header-text">
                            Votre voyage culturel
                          </div>
                        )}
                        <p className="welcome-placename">
                          {eventDetail.visit_name}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 15,
                          }}
                        >
                          <div className="avatar">
                            <img
                              src={
                                eventDetail?.guide_picture &&
                                eventDetail?.guide_picture.includes("http")
                                  ? ImageHandler(eventDetail.guide_picture)
                                  : Guide
                              }
                              alt="Avatar"
                              className="avatar-image"
                            />
                          </div>
                          <span
                            style={{
                              fontSize: "1.2rem",
                              color: "#1E2028",
                            }}
                          >
                            {eventDetail?.guide_name}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="welcome-resident-block">
                          <span className="welcome-resident-text">
                            <span className="welcome-resident-icon">
                              {user_type == "host" ? (
                                <PermContactCalendarIcon />
                              ) : (
                                <HomeIcon />
                              )}
                            </span>
                            {user_type === "host"
                              ? "Host"
                              : user_type === "residence"
                              ? "Residence"
                              : user_type === "invitee"
                              ? "Invité"
                              : user_type === "admin"
                              ? "Admin"
                              : user_type}
                          </span>
                          <p className="welcome-resident">
                            {user_type == "residence"
                              ? eventDetail.residence_name
                              : user_type == "host"
                              ? eventDetail.host_name
                              : user_type == "invitee"
                              ? eventDetail.invitee.name
                              : user_type == "admin"
                              ? eventDetail.admin_name
                              : filteredParticipants[0]?.user_name}
                          </p>
                        </div>
                        {user_type == "host" && (
                          <div className="welcome-resident-block">
                            <span className="welcome-resident-text">
                              <span className="welcome-resident-icon">
                                <HomeIcon />
                              </span>
                              {user_type === "host" && "Residence"}
                            </span>
                            <p className="welcome-resident">
                              {user_type == "host"
                                ? eventDetail.residence_name
                                : ""}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="welcome-prescreen-date">
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            fontSize: "1.125rem",
                            color: "#1E2028",
                          }}
                        >
                          <CalendarMonthIcon />
                          {formatDay(
                            eventRoomStore.convertTimeFromUTC(eventDetail.time)
                          )}
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            fontSize: "1.125rem",
                            color: "#1E2028",
                          }}
                        >
                          <WatchLaterIcon />
                          {formatTime(
                            eventRoomStore.convertTimeFromUTC(
                              eventDetail.time_in_utc
                            )
                          )}
                        </span>
                      </div>
                      <button
                        onClick={() => {
                          JoinHandler(eventDetail.time_in_utc);
                        }}
                        className="welcome-button"
                        // disabled={!visitEnabled}
                      >
                        Démarrez la visite
                        <KeyboardArrowRightIcon />
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className="welcome-header">
                  <div className="welcome-header-text">Votre Vonage</div>
                  <p className="welcome-placename">{eventDetail.visit_name}</p>
                </div>
                <div className="welcome-content">
                  <div className="welcome-prescreen-absolute">
                    <div className="welcome-prescreen">
                      <div className="welcome-image">
                        <div className="welcome-image-name">
                          {eventDetail.guide_name}
                        </div>
                        <div className="welcome-image-place">
                          <img
                            src={
                              eventDetail?.visit_picture &&
                              eventDetail?.visit_picture.includes("http")
                                ? ImageHandler(eventDetail.visit_picture)
                                : Place
                            }
                          />
                        </div>
                        <div className="avatar">
                          <img
                            src={
                              eventDetail?.guide_picture &&
                              eventDetail?.guide_picture.includes("http")
                                ? ImageHandler(eventDetail.guide_picture)
                                : Guide
                            }
                            alt="Avatar"
                            className="avatar-image"
                          />
                        </div>
                        {user_type === 'residence' &&
                          <MapContainer
                            coordinates={{
                              residence: { lat: residenceLat, lng: residenceLng },
                              guide: { lat: guideLat, lng: guideLng },
                            }}
                          />
                        }
                      </div>
                      <div>
                        <span className="welcome-resident-text">Residence</span>
                        <p className="welcome-resident">
                          {user_type == "residence"
                            ? eventDetail.residence_name
                            : eventDetail.host_name}
                        </p>
                        <div className="welcome-prescreen-date">
                          <p>
                            {formatDay(
                              eventRoomStore.convertTimeFromUTC(
                                eventDetail.time
                              )
                            )}
                          </p>
                          <p>
                            {formatTime(
                              eventRoomStore.convertTimeFromUTC(
                                eventDetail.time_in_utc
                              )
                            )}
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            JoinHandler(eventDetail.time_in_utc);
                          }}
                          className="welcome-button"
                          // disabled={!visitEnabled}
                        >
                          Démarrez la visite
                          <KeyboardArrowRightIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </>
            )}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};
export default Welcome;
