import React, { useRef, useEffect, useState } from "react";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PreviewStreamVideo from "@/components/preview-stream-video/preview-stream-video";
import { useEventRoomState } from "@/containers/root-container";
import { eventRoomStore } from "@/stores/event";
import "./local-video-control.scss";
import { ChatCmdType } from "@/utils/agora-rtm-client";

interface LocalVideoControlProps {
	stream?: any;
	video: boolean;
}

const LocalVideoControl: React.FC<LocalVideoControlProps> = ({
	stream,
	video,
}) => {
	const loadVideo = useRef<boolean>(false);
	const eventRoomState = useEventRoomState();
	const [showPreviewStream, setShowPreviewStream] = useState(false);

	// handle video mute/unmute
	useEffect(() => {
		if (!stream) return;
		// prevent already muted video
		if (!loadVideo.current) {
			if (!video) {
				if (stream.videoTrack.enabled) {
					stream.videoTrack.setEnabled(false);
				}
			}
			loadVideo.current = true;
			return;
		}

		if (video) {
			sessionStorage.setItem("videoEnabled", "1");
		} else {
			sessionStorage.setItem("videoEnabled", "0");
		}
	}, [stream, video]);

	const handleVideoClick = (evt: any) => {
		eventRoomStore.handleStreamControlClick("video", video);
		eventRoomStore.handleStreamControlClick("videoTurnedOn", video);
		const message = {
			cmd: video ? ChatCmdType.muteVideo : ChatCmdType.unmuteVideo,
			data: {
				video: !video,
				streamId: eventRoomState.rtc.localStream?.streamID,
			},
		};
		eventRoomStore.sendMessage({ type: message.cmd, msgData: message.data });
	};

	const handlePreviewStream = () => {
		setShowPreviewStream((prevCheck) => !prevCheck);
	};

	return (
		<span className="video stream-control">
			<span className="video-button-container stream-control-container">
				<span
					className={`video-button-mic_icon stream-control-primary_icon ${
						eventRoomState.me.info.role == "guide" && "center-video"
					}`}
					onClick={handleVideoClick}
				>
					{video ? <VideocamIcon /> : <VideocamOffIcon />}
				</span>
				{eventRoomState.me.info.role != "guide" && (
					<span
						className="video-button-arrow_icon stream-control-secondary_icon"
						onClick={handlePreviewStream}
					>
						{showPreviewStream ? (
							<KeyboardArrowDownIcon />
						) : (
							<KeyboardArrowUpIcon />
						)}
					</span>
				)}
			</span>
			{eventRoomState.me.info.role == "guide" ? (
				<p className="icons-tip">{video ? "Disable Video" : "Enable Video"}</p>
			) : (
				<p className="icons-tip">
					{video ? "Désactiver video" : "Activer video"}
				</p>
			)}
			{showPreviewStream && (
				<PreviewStreamVideo handlePreviewStream={handlePreviewStream} />
			)}
		</span>
	);
};

export default React.memo(LocalVideoControl);
